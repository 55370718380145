import React, { useEffect, useState } from "react";
import {
	AccordionContainer,
	BlogContainer,
	Cards,
	DropdownContainer,
	GetHelpSection,
	Loader,
	TopicsList,
} from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchTopicsData, resetTopicsData } from "./store/actions";
import { Helmet } from "react-helmet";
import FontResizeWidget from "../../components/FontResizeWidget";
import { ACCESSIBILITY_FEATURE_ENABLED } from "../../config";
import { parseHelmetTags } from "../../utils/utility";
const Topics = () => {
	const location = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const subTopicParam = location.subtopics;
	const filterParam = location.filter;

	const [mainContent, setMainContent] = useState();
	const [topicsData, setTopicsData] = useState("");
	const [topicName, setTopicName] = useState();
	const [topicList, setTopicList] = useState([]);
	const [subTopicList, setSubTopicList] = useState([]);
	const [filterVal, setFilterVal] = useState([]);
	const [dropdownVal, setDropDownVal] = useState("");
	const [subTopicSlug, setSubTopicSlug] = useState("");
	const [filteredData, setFilteredData] = useState("");
	const topicListContent = useSelector((state) => state.topicsDataList);
	const [helmetTag, setHelmetTag] = useState();
 	const [titleTag, setTitleTag] = useState();
  	const [metaTags, setMetaTags] = useState();

	useEffect(() => {
		dispatch(fetchTopicsData());
	}, []);

	useEffect(() => {
		if (topicListContent.isSuccess && topicListContent.data !== null) {
			setMainContent(topicListContent.data);
			dispatch(resetTopicsData());
		} else if (topicListContent.isError) {
			dispatch(resetTopicsData());
		}
	}, [topicListContent]);

	useEffect(() => {
		if (
			topicListContent.isSuccess &&
			mainContent !== undefined &&
			mainContent?.success?.data && !Array.isArray(mainContent.success.data)
		) {
			setTopicsData(mainContent?.success.data);
			let topicFullData = mainContent?.success.data;
			let topicData = Object.keys(topicFullData);

			// Function to update the topic list
			const updateTopicList = (list, subTopicData, topicTitle, topicSlug) => {
				let obj = { title: topicTitle, slug: topicSlug };
				let objCount = topicList.filter((item) => item.title === obj.title);
				if (!objCount.length > 0) {
					setTopicList((current) => [...current, obj]);
				}
				if (list === subTopicParam) {
					setSubTopicList(subTopicData);
					setTopicName(topicTitle);
				}
			};

			// Function to navigate to the topic
			const navigateToTopic = (subTopicParam, filterParam, subTopicData) => {
				
				if (filterParam) {
					setDropDownVal(subTopicData[filterParam].subTopicTitle);
					setFilteredData(subTopicData[filterParam]);
					setSubTopicSlug(filterParam);
					
					setHelmetTag(subTopicData[filterParam].seoMetaTags);
					// setHelmetTag()
					navigate(`/topics/${subTopicParam}/${filterParam}`);
				} else {
					setDropDownVal(Object.values(subTopicData)[0].subTopicTitle);
					setFilteredData(Object.values(subTopicData)[0]);
					setSubTopicSlug(Object.keys(subTopicData)[0]);
					setHelmetTag(subTopicData[Object.keys(subTopicData)[0]].seoMetaTags);
					// setHelmetTag()
					navigate(`/topics/${subTopicParam}/${Object.keys(subTopicData)[0]}`);
				}
			};

			// Check if the topic data includes the subTopicParam and update accordingly
			if (
				topicData.includes(subTopicParam) &&
				topicFullData[subTopicParam] !== undefined &&
				Object.keys(topicFullData[subTopicParam].subTopics).length > 0
			) {
				for (const list in topicFullData) {
					let subTopicData = topicFullData[list].subTopics;
					updateTopicList(list, subTopicData, topicFullData[list].topicTitle, list);
					if (list === subTopicParam) {
						navigateToTopic(subTopicParam, filterParam, subTopicData);
					}
				}
			} else {
				for (const list in topicFullData) {
					let subTopicData = topicFullData[list]?.subTopics;
					updateTopicList(list, subTopicData, topicFullData[list]?.topicTitle, list);
				}
				let firstElement = Object.values(topicFullData)[0];
				setTopicName(firstElement?.topicTitle);
				setSubTopicList(firstElement?.subTopics);
				setDropDownVal(Object.values(firstElement?.subTopics)[0].subTopicTitle);
				setFilteredData(Object.values(firstElement?.subTopics)[0]);
				setSubTopicSlug(Object.keys(firstElement?.subTopics)[0]);
				let slug = Object.keys(firstElement?.subTopics)[0];
				setHelmetTag(firstElement?.subTopics[slug]?.seoMetaTags);
				
				navigate(
					`/topics/${Object.keys(topicFullData)[0]}/${Object.keys(firstElement?.subTopics)[0]
					}`
				);
			}
		}
	}, [mainContent]);

	const onTopicChange = (topic, slug) => {
		setTopicName(topic);
		setFilterVal([]);
		for (const listData in topicsData) {
			if (slug === listData) {
				let subTopicData = topicsData[listData].subTopics;
				
				setSubTopicList(subTopicData);
				setDropDownVal(Object.values(subTopicData)[0].subTopicTitle);
				setSubTopicSlug(Object.keys(subTopicData)[0]);
				setFilteredData(Object.values(subTopicData)[0]);
				
				setHelmetTag(subTopicData[Object.keys(subTopicData)[0]].seoMetaTags);
				
				navigate(`/topics/${slug}/${Object.keys(subTopicData)[0]}`);
			}
		}
	};

	useEffect(() => {
		for (const filterVal in subTopicList) {
			let obj = {
				id: filterVal,
				value: subTopicList[filterVal].subTopicTitle,
			};
			setFilterVal((current) => [...current, obj]);
		}
	}, [subTopicList]);

	const onDropDownClick = (val) => {
		setDropDownVal(val);
		for (let subTopicSlug in subTopicList) {
			if (subTopicList[subTopicSlug].subTopicTitle === val) {
				setFilteredData(subTopicList[subTopicSlug]);
				
				setHelmetTag(subTopicList[subTopicSlug].seoMetaTags);
				
				navigate(`/topics/${subTopicParam}/${subTopicSlug}`);
			}
		}
	};

	useEffect(() => {
		
	}, [filteredData]);
	useEffect(() => {
		if (helmetTag !== '') {
			const {titleTag, metaTags} = parseHelmetTags(helmetTag);
			setTitleTag(titleTag);
			setMetaTags(metaTags);
		}
	}	, [helmetTag]);
	  
	return (
		<div>
			 {ACCESSIBILITY_FEATURE_ENABLED ? 
      <FontResizeWidget minSize={14} maxSize={32} step={2} defaultSize={16} />: null}
	 
	  	{helmetTag !== '' ?  <Helmet>{titleTag} {metaTags}</Helmet> :
			<Helmet>
				<title>{`${topicName ? topicName + " | " : ""
					}Topics | My Housing Gateway Static` }</title>
			</Helmet>}
			{topicListContent.isFetching ? (
				<Loader message={"Loading....."} />
			) : topicListContent.isSuccess &&
				mainContent !== undefined &&
				!Array.isArray(mainContent?.success.data) ? (
				<div className="topics">
					<TopicsList
						topicListData={topicList}
						title={topicName}
						onTopicChange={onTopicChange}
						filterSlug={subTopicSlug}
					/>
					<div>
						<div className="bg-white py-3 py-md-5 topic-blog-container">
							<div className="container">
								<h2 className="topic-title textBlue text-title-24">
									{topicName}
								</h2>
								<DropdownContainer
									title={
										"I want to read information on this topic relating to"
									}
									color={"lightBlue"}
									dropdownvalue={dropdownVal}
									options={filterVal}
									onDropDownChange={onDropDownClick}
								/>
							</div>
							<div className="container">
								{Array.isArray(filteredData.contentBlocks) &&
									filteredData.contentBlocks ? (
									<div>
										{filteredData.contentBlocks.map((blogItem) => {
											return <BlogContainer blogItem={blogItem} />;
										})}
									</div>
								) : null}
							</div>
						</div>
						{Array.isArray(filteredData.caseStudies) &&
							filteredData.caseStudies.length ? (
							<div className="bg-cream case-card">
								<div className="container">
									{filteredData.caseStudies ? (
										<h3 className="text-title-26">
											Case studies from private tenants
										</h3>
									) : null}
									{filteredData.caseStudies ? (
										<ul className="card-container d-flex flex-column flex-wrap flex-md-row justify-content-between case-list-container">
											{filteredData.caseStudies.map((caseItem) => {
												return (
													<Cards
														type="case"
														title={`${caseItem.caseStudyName}, ${caseItem.caseStudyLocation}`}
														description={caseItem.caseStudyTestimonial}
													/>
												);
											})}
										</ul>
									) : null}
								</div>
							</div>
						) : null}
						{Array.isArray(filteredData.faqs) && filteredData.faqs.length ? (
							<div className="bg-white py-4 py-md-5 topic-faq">
								<div className="container">
									{filteredData.faqs ? (
										<div>
											<h3 className="text-title-28 faq-title">
												Frequently asked questions
											</h3>
											<AccordionContainer list={filteredData.faqs} accordionClicked={()=>{}}/>
										</div>
									) : null}
								</div>
							</div>
						) : null}
						{filteredData.externalSites &&
							mainContent !== undefined &&
							filteredData.externalSites.length > 0 ? (
							<div>
								<GetHelpSection
									title="Where can I get further information?"
									description="This page provides general information about tenant rights, created by the TDS Charitable Foundation with support from Advice for Renters. For more details, see the links below"
									list={filteredData.externalSites}
								/>
							</div>
						) : null}
					</div>
				</div>
			) : topicListContent.isSuccess &&
				Array.isArray(mainContent?.success.data) ? (
				<div className="container">
					<p className="text-24 mt-4">No data found</p>
				</div>
			) : null}
		</div>
	);
};

export default Topics;
