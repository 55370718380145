import React, { useEffect, useState } from 'react'
import { Accordion, Button } from 'react-bootstrap';
import { AccordionContainer, Loader, RadioDropdown } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFaqData, resetFaqData } from './store/actions';
import { Helmet } from 'react-helmet';
import FontResizeWidget from '../../components/FontResizeWidget';
import { useNavigate, useParams } from 'react-router-dom';
import { ACCESSIBILITY_FEATURE_ENABLED } from '../../config';
import { parseHelmetTags } from '../../utils/utility';
const FAQ = () => {

    const location = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [faqTopic, setFaqTopic] = useState('');
    const [faqQuestions, setFaqQuestions] = useState([]);
    const [topic, setTopics] = useState([]);
    const [activeTab, setActiveTab] = useState();
    const [questioneer, setQuestioneer] = useState();
    const [faqMetaData,setFaqMetaData] = useState();
    const faqResult = useSelector((state) => state.faqList);
    const topicTitle = location.title;
    const [helmetTag, setHelmetTag] = useState('');
    const [titleTag, setTitleTag] = useState();
    const [metaTags, setMetaTags] = useState();

    useEffect(() => {
        dispatch(fetchFaqData());
    }, [])

    useEffect(() => {
        if (faqResult.isSuccess && faqResult.data !== null) {
            let data = faqResult.data.success.data.faqTopics;
            let questions = faqResult.data.success.data.faqs;
            setFaqMetaData(faqResult.data.success.data.faqMetaData);
            setFaqQuestions(questions);
            let firstTitle = '';
            for (const topics in data) {
                let obj = {
                    title: data[topics],
                    slug: topics,
                }
                if (firstTitle === '') {
                    firstTitle = topics;
                }
                setTopics((current) => {
                    if (!current.some(item => item.slug === obj.slug)) {
                        return [...current, obj];
                    }
                    return current;
                });
            }
            // create a regex to replace special characters with blank space and replace the space with hyphen
            
            dispatch(resetFaqData())
        } else if (faqResult.error) {
            dispatch(resetFaqData())
        }
    }, [faqResult]);


    useEffect(() => {
        if(topic.length > 0){
        if(topicTitle !== undefined && topicTitle !== null && topicTitle !== '' ){
            const title = topicTitle.replace(/-/g, ' ');
            const slug = topic.filter(((item) => item.slug.toLowerCase() === title));
            setHelmetTag(faqMetaData[slug[0].slug]);
            setActiveTab(slug[0].title);
            setQuestioneer(faqQuestions[slug[0].slug]);
            navigate(`/faq/${topicTitle}`);
            
        }else{
            // navigate('/faq');
        setActiveTab(Object.values(topic)[0]?.title)
        setQuestioneer(faqQuestions[Object.values(topic)[0]?.slug])
        setHelmetTag(faqMetaData[Object.values(topic)[0]?.slug]);
        let slug = Object.values(topic)[0]?.slug;
        slug = slug.toLowerCase().replace(/ /g, '-')
        navigate(`/faq/${slug}`);
        
        }
    }
    }, [topic])

    useEffect(() => { }, [activeTab])

    const onTabChange = (title, slug) => {
        setHelmetTag(faqMetaData[slug])
        setActiveTab(title);
        setQuestioneer(faqQuestions[slug]);
        let titleSlug = slug.toLowerCase().replace(/ /g, '-')
        navigate(`/faq/${titleSlug}`);
    };

    useEffect(() => {
    }, [faqQuestions])

    useEffect(() => {
            if (helmetTag !== '') {
                const {titleTag, metaTags} = parseHelmetTags(helmetTag);
                setTitleTag(titleTag);
                setMetaTags(metaTags);
            }
        }	, [helmetTag]);

    return (

        <div className='faq'>
             {ACCESSIBILITY_FEATURE_ENABLED ? 
      <FontResizeWidget minSize={14} maxSize={32} step={2} defaultSize={16} />: null}
            
                { helmetTag !== '' ? <Helmet>{titleTag} {metaTags} </Helmet> :
               <Helmet>
               <title> {`${activeTab + " | "}FAQ | My Housing Gateway`}</title>
              </Helmet>}
            {faqResult.isFetching ? (
                <Loader message={"Loading....."} />
            ) : faqResult.isSuccess && !Array.isArray(faqQuestions) ? (
                <div className='container'>
                    <h2 className='mt-4 mt-md-5 text-title-28 darkGrey'>Frequently asked questions</h2>
                    <div className='d-flex justify-content-between flex-column flex-md-row faq-content'>
                        <div className='col-md-3 d-none d-md-block'>
                            <h3 className='text-sub-title-20 faq-topic-title'>Topics</h3>
                            {
                                Array.isArray(topic) && topic.length > 0 ? (
                                    <ul className='d-flex flex-column mt-4'>
                                        {
                                            topic.map((item, index) => {
                                                return (
                                                    <li className={activeTab === item.title ? 'flex-grow-1 alig-items-stretch mb-3 active' : 'flex-grow-1 alig-items-stretch mb-3'} key={index}><Button className='d-block bg-white text-sub-title black fw-normal' onClick={() => onTabChange(item.title, item.slug)}>{item.title}</Button></li>
                                                )
                                            })
                                        }
                                    </ul>
                                ) : null
                            }
                        </div>
                        <RadioDropdown title={activeTab} options={topic} onChange={onTabChange} />

                        <div className='accordion-list-container col-md-8 bg-white p-md-4 mb-5'>
                            {
                                Array.isArray(questioneer) && questioneer.length > 0 ? (
                                    <AccordionContainer list={questioneer} accordionClicked={()=>{}}/>
                                ) : null
                            }
                        </div>
                    </div>
                </div>
            ) : faqResult.isSuccess && Array.isArray(faqQuestions) ?
                <div className='container'>
                    <p className='text-24 mt-4'>No data found</p>
                </div>
                : null}


        </div>
    )
}

export default FAQ
