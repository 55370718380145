import React, { useEffect, useState } from 'react'
import { BackToAction, EmailModal, GetHelpSection, InformationContent, ShareModal } from '../../components'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { fetchHomepageData } from '../homepage/store/actions'
import { useLocation, useNavigate, useNavigation } from 'react-router-dom';
import { renderToString } from "react-dom/server";
import { EmailLogo, EmailTemplateBanner, TDSLogo, TemplateBanner } from '../../config/images'
import { emailTemplate } from './store/actions'
import { BaseUrl ,ACCESSIBILITY_FEATURE_ENABLED} from '../../config'

import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Helmet } from 'react-helmet'
import FontResizeWidget from '../../components/FontResizeWidget'

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const GeneratedLetter = () => {
    const [emailModal, setEmailModal] = useState(false);
    const [externalLinkList, setExternalLinkList] = useState([]);
    const [shareEnable, setShareEnable] = useState(false);
    const [letterTemplate, setLetterTemplate] = useState([]);
    const [informationContent, setInformationContent] = useState({});
    const [letterBuildData, setLetterBuilderData] = useState([]);
    const [copied, setCopied] = useState(false);
    const dispatch = useDispatch();
    const externalLinkData = useSelector((state) => state.home);
    const location = useLocation();
    const navigate = useNavigate();

    const curDate = new Date().getDate() + '/' + (new Date().getMonth() + 1) + '/' + new Date().getFullYear();
    const LogoImage = `${BaseUrl}/email/Logo.png`;
    const BannerTemplage = `${BaseUrl}/email/template-banner.png`;

    const toastMessage = (toastMessage) => {
        toast(toastMessage);

    }

    const htmlContent = `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "https://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="https://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
    <head>

    </head>
    <body style="background-color: #fff;  font-family: Helvetica, Arial, sans-serif;">
        <div style="width: 100%">
            <img width="100" style="width: 100%;" src=${BannerTemplage} alt="banner" />
        </div>
        <table style="width: 95%; margin: 0 auto; display: block;">
            <tr style="display: block; width: 100%" >
                <td style="display: block; margin-top: 1.688rem;">
                    <img src="${LogoImage}" style="width: 10%; min-width: 7rem" alt="TDS Logo" />
                </td>
            </tr>
            <tr style="margin-top: 3.188rem; display: block;">
                <td>Hello,</td>
            </tr>
            <tr style="margin-top: 3.188rem;">
                <td>
                    <p>Thank you for taking the time to visit our support website for people who have issues with their rental property.</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Here is a copy of your personalised letter template from <a href="#" title="TDS" style="color: inherit; font-weight: 700;">TDS Charitable Foundation</a> based on the answers you filled in. You can copy and paste this letter into a new email to the intended recipient, or print it and post it in a stamped envelope.</p>
                </td>
            </tr>
            
        </table>
        ${Array.isArray(letterTemplate) && letterTemplate.length > 0 ? (
            `<table style="width: 95%; margin: 0 auto; padding: 1rem; display: block; background-color: #FAFAFA;">` +
            `${letterTemplate.map((data, index) => {
                let _value = letterBuildData.filter(obj => obj.title === data.valueOF);
                let _isHorizontalView = data.hasOwnProperty('isHorizontal') && data.isHorizontal;

                if (index < location.state?.maxLeftIndex && data.alignment !== 'left' && data.alignment !== 'side-right') {
                    return (
                        `<tr key=${index} style="display: flex; justify-content: space-between">` +
                        `<td style="display: inline-block; min-width: 9.375rem; text-align: right">` +
                        `<h3 class="text-14 col-6">${data.title}</h3>` +
                        `</td>` +
                        `<td>` +
                        `${_value.length > 0 ? _value[0].value === '' ? `<h3 class="text-14-26 highlight mb-2 text-center" style="text-align: right; margin-top: 0">${data.default}</h3>` :
                            `<h3 class="text-14-26 text-right col-3" style="text-align: right; margin-top: 0; margin-bottom: 0">${_value[0].value}</h3>` : data.valueOF === 'Date' ? `<h3 class="text-14-26 text-right col-3" style="text-align: right; margin-top: 0">${data.default}</h3>` : ''}` +
                        `</td>` +
                        `</tr>`
                    );
                } else {
                    //.  console.log("data inside default2:-", data.default2);
                    if (data?.default2 !== undefined && data?.default2 !== '') {
                        let _value1 = letterBuildData.filter(obj => obj.title === data?.valueOF1);
                        let _value2 = letterBuildData.filter(obj => obj.title === data?.valueOF2);
                        return (
                            `<tr key=${index} style="display: flex; justify-content: space-between">` +
                            `<td colspan="2">` +
                            `<div class="align-items-start mt-3 mb-3">` +
                            `<p class="text-14 mb-0" style="font-weight: 400; word-break: break-word">` +
                            `${data.leftdescription}` +
                            `${_value.length > 0 ? _value[0].value === '' ? `<strong class="text-14-26 d-inline-block highlight ml-2 mr-2 text-center">${data.default}</strong>` :
                                _value[0].type === 'ck-editor' ? `<div class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; font-weight: 700">${_value[0].value}</div>` : `<strong class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; margin-top: 0">${_value[0].value}</strong>` : data.valueOF === 'Date' ? `<strong class="text-14-26 ml-2 d-inline-block text-right col-3">${data.default}</strong>` : ''}` +
                            `${data.rightdescription}` +
                            `${data.leftdescription1}` +
                            `${_value1.length > 0 ? _value1[0].value === '' ? `<strong class="text-14-26 d-inline-block highlight ml-2 text-center">${data.default1}</strong>` :
                                _value1.type === 'ck-editor' ? `<div class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; font-weight: 700">${_value1[0].value}</div>` : `<strong class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; margin-top: 0">${_value1[0].value}</strong>` : data.valueOF1 === 'Date' ? `<strong class="text-14-26 ml-2 d-inline-block text-right col-3">${data.default1}</strong>` : ''}` +
                            `${data.rightdescription1}` +
                            `${data?.leftdescription2}` +
                            `${_value2.length > 0 ? _value2[0].value === '' ? `<strong class="text-14-26 d-inline-block highlight ml-2 text-center">${data?.default2}</strong>` :
                                _value2.type === 'ck-editor' ? `<div class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; font-weight: 700">${_value2[0].value}</div>` : `<strong class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; margin-top: 0">${_value2[0].value}</strong>` : data.valueOF2 === 'Date' ? `<strong class="text-14-26 ml-2 d-inline-block text-right col-3">${data.default2}</strong>` : ''}` +
                            `${data?.rightdescription2}` +
                            `</p>` +
                            `</div>` +
                            `</td>` +
                            `</tr>`
                        );
                    }
                    else
                        if (data?.default1 !== undefined && data.default1 !== '') {
                            let _value1 = letterBuildData.filter(obj => obj.title === data.valueOF1);

                            return (
                                `<tr key=${index} style="display: flex; justify-content: space-between">` +
                                `<td colspan="2">` +
                                `<div class="align-items-start mt-3 mb-3" style=" align-center: 'baseline'">` +
                                `<p class="text-14 mb-0" style="font-weight: 400; word-break: break-word">` +
                                `${data.leftdescription}` +
                                `${_value.length > 0 ? _value[0].value === '' ? `<h3 class="text-14-26 d-inline-block highlight ml-2 mr-2 text-center">${data.default}</h3>` :
                                    _value[0].type === 'ck-editor' ? `<div class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; font-weight: 700">${_value[0].value}</div>` : `<strong class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; margin-top: 0">${_value[0].value}</strong>` : data.valueOF === 'Date' ? `<strong class="text-14-26 ml-2 d-inline-block text-right col-3">${data.default}</strong>` : ''}` +
                                `${data.rightdescription}` +
                                `${data.leftdescription1}` +
                                `${_value1.length > 0 ? _value1[0].value === '' ? `<strong class="text-14-26 d-inline-block highlight ml-2 text-center">${data.default1}</strong>` :
                                    _value1.type === 'ck-editor' ? `<div class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; font-weight: 700">${_value1[0].value}</div>` : `<strong class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; margin-top: 0">${_value1[0].value}</strong>` : data.valueOF1 === 'Date' ? `<strong class="text-14-26 ml-2 d-inline-block text-right col-3">${data.default1}</strong>` : ''}` +
                                `${data.rightdescription1}` +
                                `</p>` +
                                `</div>` +
                                `</td>` +
                                `</tr>`
                            );
                        } else if (data.alignment === 'side-right') {
                            return (
                                `<tr key=${index} style="display: flex; justify-content: space-between">` +
                                `<td colspan="2">` +
                                `<div class="align-items-start mt-3 mb-3">` +
                                `<p class="text-14 mb-0" style="font-weight: 400" style="align-item: baseline">${data.leftdescription}` +
                                `${_value.length > 0 ? _value[0].value === '' ? `<strong class="text-14-26 d-inline-block highlight ml-2 text-center">${data.default}</strong>` :
                                    _value[0].type === 'ck-editor' ? `<strong class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; font-weight: 700">${_value[0].value}</strong>` : `<strong class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-top: 0">${_value[0].value}</strong>` : data.valueOF === 'Date' ? `<strong class="text-14-26 ml-2 d-inline-block text-right col-3">${data.default}</strong>` : ''}` +
                                `</p>` +
                                `${data.rightdescription !== '' ? `<p class="text-14 mb-0 d-flex" style="font-weight: 400">${data.rightdescription}</p>` : ''}` +
                                `</div>` +
                                `</td>` +
                                `</tr>`
                            );
                        } else if (data.alignment === 'center') {
                            return (
                                `<tr key=${index} style=" justify-content: space-between">` +
                                `<td colspan="2">` +
                                `<div class="align-items-start mt-3 mb-3" style="flex; align-center: 'baseline'">` +
                                `<p class="text-14 mb-0" style="font-weight: 400">${data.leftdescription}` +
                                `${_value.length > 0 ? _value[0].value === '' ? `<strong class="text-14-26 d-inline-block highlight ml-2 text-center">${data.default}</strong>` :
                                    _value[0].type === 'editor' ? `<div class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; font-weight: 700">${_value[0].value}</div>` : `<strong class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem">${_value[0].value}</strong>` : data.valueOF === 'Date' ? `<strong class="text-14-26 ml-2 d-inline-block text-right col-3">${data.default}</strong>` : ''}` +
                                `${data.rightdescription !== '' ? data.rightdescription : ''}` +
                                `</p>` +
                                `</div>` +
                                `</td>` +
                                `</tr>`
                            );
                        } else {
                            return (
                                `<tr key=${index} style="justify-content: space-between">` +
                                `<td colspan="2">` +
                                `<div class="${data.title === '' ? ' align-items-start' : 'align-items-start mt-3'}">` +
                                `<p class="text-14-normal mb-2">${data.title}</p>` +
                                `${_value.length > 0 ? _value[0].value === '' ? `<h3 class="text-14 highlight mb-2 text-center bg-white">${data.default}</h3>` :
                                    _value[0].type === 'ck-editor' ? `<div class="text-14-26 d-inline-block text-right  mt-0" style="line-height: unset; font-weight: 700">${_value[0].value}</div>` : `<h3 class="text-14 mt-0 mb-0">${_value[0].value}</h3>` : ''}` +
                                `</div>` +
                                `</td>` +
                                `</tr>`
                            );
                        }
                }
            }).join('')}` +
            `</table>`
        ) : ''}
        
        <table style="width: 95%;  margin: 2rem auto 3rem; display: block;">
        <tbody style="width: 100%; display: block">
            <tr style="width: 100%; display: block">
                <td style="display: block">
                    <p>For more information and resources please <a href='https://www.tdsfoundation.org.uk' target="_blank" style="font-weight: bold; text-decoration: underline; color: #3d4852">visit our website.</a></p>
                </td>
            </tr>
            <tr style="display: block; width: 100%">
                <td style="display: block;">
                    <p>Best wishes,</p>
                </td>
            </tr>
            <tr style="display: block; width: 100%">
                <td style="display: block;">
                    <p><i>My Housing Gateway Team</i></p>
                </td>
            </tr>
            <tr style="display: block; width: 100%">
                <td style="display: block; width="100%" margin-top: 1.688rem;">
                    <img src="${LogoImage}" style="width: 10%; min-width: 7rem" alt="TDS Logo" />
                </td>
            </tr>
            </tbody>
        </table>
    </body>
</html>
`;

    const printContent = `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "https://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="https://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
    <head>
        <style>
            body {
                background-color: #fff;
                font-size: 1.9vw;
                font-family: Helvetica, arial, sans-serif
            }
            .background {
                background-color: #FAFAFA;
            }

            p {
                margin-bottom: 0;
            }

            @media print {
                body {
                    -webkit-print-color-adjust: exact;
                    print-color-adjust: exact;
                    background-color: #fff !important;
                }
                .background {
                    background-color: #FAFAFA !important;
                }
            }
        </style>
    </head>
    <body style="background-color: #fff;">
        <div>
            <img width="100" style="width: 100%;" src="${EmailTemplateBanner}" alt="banner" />
        </div>
        ${Array.isArray(letterTemplate) && letterTemplate.length > 0 ? (
            `<table style="width: 95%; margin: 0 auto; padding: 1rem; display: block; background-color: #FAFAFA;">` +
            `${letterTemplate.map((data, index) => {
                let _value = letterBuildData.filter(obj => obj.title === data.valueOF);
                let _isHorizontalView = data.hasOwnProperty('isHorizontal') && data.isHorizontal;

                if (index < location.state?.maxLeftIndex && data.alignment !== 'left' && data.alignment !== 'side-right') {
                    return (
                        `<tr key=${index} style="display: flex; justify-content: space-between">` +
                        `<td style="display: inline-block; min-width: 9.375rem; text-align: right">` +
                        `<h3 class="text-14 col-6">${data.title}</h3>` +
                        `</td>` +
                        `<td>` +
                        `${_value.length > 0 ? _value[0].value === '' ? `<h3 class="text-14-26 highlight mb-2 text-center" style="text-align: right; margin-top: 0">${data.default}</h3>` :
                            `<h3 class="text-14-26 text-right col-3" style="text-align: right; margin-top: 0; margin-bottom: 0">${_value[0].value}</h3>` : data.valueOF === 'Date' ? `<h3 class="text-14-26 text-right col-3" style="text-align: right; margin-top: 0">${data.default}</h3>` : ''}` +
                        `</td>` +
                        `</tr>`
                    );
                } else {
                    if (data?.default2 !== undefined && data?.default2 !== '') {
                        let _value1 = letterBuildData.filter(obj => obj.title === data?.valueOF1);
                        let _value2 = letterBuildData.filter(obj => obj.title === data?.valueOF2);
                        return (
                            `<tr key=${index} style="display: flex; justify-content: space-between">` +
                            `<td colspan="2">` +
                            `<div class="align-items-start mt-3 mb-3">` +
                            `<p class="text-14 mb-0" style="font-weight: 400; word-break: break-word">` +
                            `${data.leftdescription}` +
                            `${_value.length > 0 ? _value[0].value === '' ? `<strong class="text-14-26 d-inline-block highlight ml-2 mr-2 text-center">${data.default}</strong>` :
                                _value[0].type === 'ck-editor' ? `<div class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; font-weight: 700">${_value[0].value}</div>` : `<strong class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; margin-top: 0">${_value[0].value}</strong>` : data.valueOF === 'Date' ? `<strong class="text-14-26 ml-2 d-inline-block text-right col-3">${data.default}</strong>` : ''}` +
                            `${data.rightdescription}` +
                            `${data.leftdescription1}` +
                            `${_value1.length > 0 ? _value1[0].value === '' ? `<strong class="text-14-26 d-inline-block highlight ml-2 text-center">${data.default1}</strong>` :
                                _value1.type === 'ck-editor' ? `<div class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; font-weight: 700">${_value1[0].value}</div>` : `<strong class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; margin-top: 0">${_value1[0].value}</strong>` : data.valueOF1 === 'Date' ? `<strong class="text-14-26 ml-2 d-inline-block text-right col-3">${data.default1}</strong>` : ''}` +
                            `${data.rightdescription1}` +
                            `${data?.leftdescription2}` +
                            `${_value2.length > 0 ? _value2[0].value === '' ? `<strong class="text-14-26 d-inline-block highlight ml-2 text-center">${data?.default2}</strong>` :
                                _value2.type === 'ck-editor' ? `<div class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; font-weight: 700">${_value2[0].value}</div>` : `<strong class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; margin-top: 0">${_value2[0].value}</strong>` : data.valueOF2 === 'Date' ? `<strong class="text-14-26 ml-2 d-inline-block text-right col-3">${data.default2}</strong>` : ''}` +
                            `${data?.rightdescription2}` +
                            `</p>` +
                            `</div>` +
                            `</td>` +
                            `</tr>`
                        );
                    }
                    else if (data?.default1 !== undefined && data.default1 !== '') {
                        let _value1 = letterBuildData.filter(obj => obj.title === data.valueOF1);

                        return (
                            `<tr key=${index} style="display: flex; justify-content: space-between">` +
                            `<td colspan="2">` +
                            `<div class="align-items-start mt-3 mb-3">` +
                            `<p class="text-14 mb-0" style="font-weight: 400; word-break: break-word">` +
                            `${data.leftdescription}` +
                            `${_value.length > 0 ? _value[0].value === '' ? `<strong class="text-14-26 d-inline-block highlight ml-2 mr-2 text-center">${data.default}</strong>` :
                                _value[0].type === 'ck-editor' ? `<div class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; font-weight: 700">${_value[0].value}</div>` : `<strong class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; margin-top: 0">${_value[0].value}</strong>` : data.valueOF === 'Date' ? `<strong class="text-14-26 ml-2 d-inline-block text-right col-3">${data.default}</strong>` : ''}` +
                            `${data.rightdescription}` +
                            `${data.leftdescription1}` +
                            `${_value1.length > 0 ? _value1[0].value === '' ? `<strong class="text-14-26 d-inline-block highlight ml-2 text-center">${data.default1}</strong>` :
                                _value1.type === 'ck-editor' ? `<div class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; font-weight: 700">${_value1[0].value}</div>` : `<strong class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; margin-top: 0">${_value1[0].value}</strong>` : data.valueOF1 === 'Date' ? `<strong class="text-14-26 ml-2 d-inline-block text-right col-3">${data.default1}</strong>` : ''}` +
                            `${data.rightdescription1}` +
                            `</p>` +
                            `</div>` +
                            `</td>` +
                            `</tr>`
                        );
                    } else if (data.alignment === 'side-right') {
                        return (
                            `<tr key=${index} style="display: flex; justify-content: space-between">` +
                            `<td colspan="2">` +
                            `<div class="align-items-start mt-3 mb-3">` +
                            `<p class="text-14 mb-0" style="font-weight: 400">${data.leftdescription}` +
                            `${_value.length > 0 ? _value[0].value === '' ? `<strong class="text-14-26 d-inline-block highlight ml-2 text-center">${data.default}</strong>` :
                                _value[0].type === 'ck-editor' ? `<strong class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; font-weight: 700">${_value[0].value}</strong>` : `<strong class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-top: 0">${_value[0].value}</strong>` : data.valueOF === 'Date' ? `<strong class="text-14-26 ml-2 d-inline-block text-right col-3">${data.default}</strong>` : ''}` +
                            `</p>` +
                            `${data.rightdescription !== '' ? `<p class="text-14 mb-0 d-flex" style="font-weight: 400">${data.rightdescription}</p>` : ''}` +
                            `</div>` +
                            `</td>` +
                            `</tr>`
                        );
                    } else if (data.alignment === 'center') {
                        return (
                            `<tr key=${index} style="display: flex; justify-content: space-between">` +
                            `<td colspan="2">` +
                            `<div class="align-items-start mt-3 mb-3">` +
                            `<p class="text-14 mb-0" style="font-weight: 400">${data.leftdescription}` +
                            `${_value.length > 0 ? _value[0].value === '' ? `<strong class="text-14-26 d-inline-block highlight ml-2 text-center">${data.default}</strong>` :
                                _value[0].type === 'editor' ? `<div class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; font-weight: 700">${_value[0].value}</div>` : `<strong class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem">${_value[0].value}</strong>` : data.valueOF === 'Date' ? `<strong class="text-14-26 ml-2 d-inline-block text-right col-3">${data.default}</strong>` : ''}` +
                            `${data.rightdescription !== '' ? data.rightdescription : ''}` +
                            `</p>` +
                            `</div>` +
                            `</td>` +
                            `</tr>`
                        );
                    } else {
                        return (
                            `<tr key=${index} style="display: flex; justify-content: space-between">` +
                            `<td colspan="2">` +
                            `<div class="${data.title === '' ? 'd-flex flex-column align-items-start' : 'd-flex flex-column align-items-start mt-3'}">` +
                            `<p class="text-14-normal mb-2">${data.title}</p>` +
                            `${_value.length > 0 ? _value[0].value === '' ? `<h3 class="text-14 highlight mb-2 text-center bg-white">${data.default}</h3>` :
                                _value[0].type === 'ck-editor' ? `<div class="text-14-26 d-inline-block text-right  mt-0" style="line-height: unset; font-weight: 700">${_value[0].value}</div>` : `<h3 class="text-14 mt-0">${_value[0].value}</h3>` : ''}` +
                            `</div>` +
                            `</td>` +
                            `</tr>`
                        );
                    }
                }
            }).join('')}` +
            `</table>`
        ) : ''}
        
    </body>
</html>
`;
    const copiedPlainText = `
<body style="background-color: #fff;">

${Array.isArray(letterTemplate) && letterTemplate.length > 0 ? (
            `<table style="width: 95%; margin: 0 auto; padding: 1rem; display: block; background-color: #FAFAFA;">` +
            `${letterTemplate.map((data, index) => {
                let _value = letterBuildData.filter(obj => obj.title === data.valueOF);
                let _isHorizontalView = data.hasOwnProperty('isHorizontal') && data.isHorizontal;

                if (index < location.state?.maxLeftIndex && data.alignment !== 'left' && data.alignment !== 'side-right') {
                    return (
                        `<tr key=${index} style="display: flex; justify-content: space-between">` +
                        `<td style="display: inline-block; min-width: 9.375rem; text-align: right">` +
                        `<h3 class="text-14 col-6">${data.title}</h3>` +
                        `</td>` +
                        `<td>` +
                        `${_value.length > 0 ? _value[0].value === '' ? `<h3 class="text-14-26 highlight mb-2 text-center" style="text-align: right; margin-top: 0">${data.default}</h3>` :
                            `<h3 class="text-14-26 text-right col-3" style="text-align: right; margin-top: 0; margin-bottom: 0">${_value[0].value}</h3>` : data.valueOF === 'Date' ? `<h3 class="text-14-26 text-right col-3" style="text-align: right; margin-top: 0">${data.default}</h3>` : ''}` +
                        `</td>` +
                        `</tr>`
                    );
                } else {
                    if (data?.default2 !== undefined && data?.default2 !== '') {
                        let _value1 = letterBuildData.filter(obj => obj.title === data?.valueOF1);
                        let _value2 = letterBuildData.filter(obj => obj.title === data?.valueOF2);
                        return (
                            `<tr key=${index} style="display: flex; justify-content: space-between">` +
                            `<td colspan="2">` +
                            `<div class="align-items-start mt-3 mb-3">` +
                            `<p class="text-14 mb-0" style="font-weight: 400; word-break: break-word">` +
                            `${data.leftdescription}` +
                            `${_value.length > 0 ? _value[0].value === '' ? `<strong class="text-14-26 d-inline-block highlight ml-2 mr-2 text-center">${data.default}</strong>` :
                                _value[0].type === 'ck-editor' ? `<div class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; font-weight: 700">${_value[0].value}</div>` : `<strong class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; margin-top: 0">${_value[0].value}</strong>` : data.valueOF === 'Date' ? `<strong class="text-14-26 ml-2 d-inline-block text-right col-3">${data.default}</strong>` : ''}` +
                            `${data.rightdescription}` +
                            `${data.leftdescription1}` +
                            `${_value1.length > 0 ? _value1[0].value === '' ? `<strong class="text-14-26 d-inline-block highlight ml-2 text-center">${data.default1}</strong>` :
                                _value1.type === 'ck-editor' ? `<div class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; font-weight: 700">${_value1[0].value}</div>` : `<strong class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; margin-top: 0">${_value1[0].value}</strong>` : data.valueOF1 === 'Date' ? `<strong class="text-14-26 ml-2 d-inline-block text-right col-3">${data.default1}</strong>` : ''}` +
                            `${data.rightdescription1}` +
                            `${data?.leftdescription2}` +
                            `${_value2.length > 0 ? _value2[0].value === '' ? `<strong class="text-14-26 d-inline-block highlight ml-2 text-center">${data?.default2}</strong>` :
                                _value2.type === 'ck-editor' ? `<div class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; font-weight: 700">${_value2[0].value}</div>` : `<strong class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; margin-top: 0">${_value2[0].value}</strong>` : data.valueOF2 === 'Date' ? `<strong class="text-14-26 ml-2 d-inline-block text-right col-3">${data.default2}</strong>` : ''}` +
                            `${data?.rightdescription2}` +
                            `</p>` +
                            `</div>` +
                            `</td>` +
                            `</tr>`
                        );
                    }
                    else if (data?.default1 !== undefined && data.default1 !== '') {
                        let _value1 = letterBuildData.filter(obj => obj.title === data.valueOF1);

                        return (
                            `<tr key=${index} style="display: flex; justify-content: space-between">` +
                            `<td colspan="2">` +
                            `<div class="align-items-start mt-3 mb-3" style="margin-top:12;">` +
                            `<p class="text-14 mb-0" style="font-weight: 400; word-break: break-word">` +
                            `${data.leftdescription}` +
                            `${_value.length > 0 ? _value[0].value === '' ? `<h3 class="text-14-26 d-inline-block highlight ml-2 mr-2 text-center">${data.default}</h3>` :
                                _value[0].type === 'ck-editor' ? `<div class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; font-weight: 700">${_value[0].value}</div>` : `<strong class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; margin-top: 0">${_value[0].value}</strong>` : data.valueOF === 'Date' ? `<strong class="text-14-26 ml-2 d-inline-block text-right col-3">${data.default}</strong>` : ''}` +
                            `${data.rightdescription}` +
                            `${data.leftdescription1}` +
                            `${_value1.length > 0 ? _value1[0].value === '' ? `<h3 class="text-14-26 d-inline-block highlight ml-2 text-center">${data.default1}</h3>` :
                                _value1.type === 'ck-editor' ? `<div class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; font-weight: 700">${_value1[0].value}</div>` : `<strong class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem; margin-top: 0">${_value1[0].value}</strong>` : data.valueOF1 === 'Date' ? `<strong class="text-14-26 ml-2 d-inline-block text-right col-3">${data.default1}</strong>` : ''}` +
                            `${data.rightdescription1}` +
                            `</p>` +
                            `</div>` +
                            `</td>` +
                            `</tr>`
                        );
                    } else if (data.alignment === 'side-right') {
                        return (
                            `<tr key=${index} style="display: flex; justify-content: space-between">` +
                            `<td colspan="2">` +
                            `<div class="align-items-start mt-3 mb-3" style="margin-top:12">` +
                            `<p class="text-14 mb-0" style="font-weight: 400">${data.leftdescription}` +
                            `${_value.length > 0 ? _value[0].value === '' ? `<h3 class="text-14-26 d-inline-block highlight ml-2 text-center">${data.default}</h3>` :
                                _value[0].type === 'ck-editor' ? `<div class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; font-weight: 700">${_value[0].value}</div>` : `<strong class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-top: 0">${_value[0].value}</strong>` : data.valueOF === 'Date' ? `<strong class="text-14-26 ml-2 d-inline-block text-right col-3">${data.default}</strong>` : ''}` +
                            `${data.rightdescription1}` +
                            `</p>` +
                            `</div>` +
                            `</td>` +
                            `</tr>`
                        );
                    } else if (data.alignment === 'center') {
                        return (
                            `<tr key=${index} style="display: flex; justify-content: space-between">` +
                            `<td colspan="2">` +
                            `<div class="align-items-start mt-3 mb-3" style="margin-top:12">` +
                            `<p class="text-14 mb-0" style="font-weight: 400">${data.leftdescription}` +
                            `${_value.length > 0 ? _value[0].value === '' ? `<h3 class="text-14-26 d-inline-block highlight ml-2 text-center">${data.default}</h3>` :
                                _value[0].type === 'editor' ? `<div class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; font-weight: 700">${_value[0].value}</div>` : `<strong class="text-14-26 d-inline-block text-right ml-2" style="line-height: unset; margin-left: 0.5rem; margin-right: 0.5rem">${_value[0].value}</strong>` : data.valueOF === 'Date' ? `<strong class="text-14-26 ml-2 d-inline-block text-right col-3">${data.default}</strong>` : ''}` +
                            `${data.rightdescription !== '' ? data.rightdescription : ''}` +
                            `</p>` +
                            `</div>` +
                            `</td>` +
                            `</tr>`
                        );
                    } else {
                        return (
                            `<tr key=${index} style="display: flex; justify-content: space-between">` +
                            `<td colspan="2">` +
                            `<div class="${data.title === '' ? 'd-flex flex-column align-items-start' : 'd-flex flex-column align-items-start mt-3'}">` +
                            `<p class="text-14-normal mb-2" style="margin-top:12">${data.title}</p>` +
                            `${_value.length > 0 ? _value[0].value === '' ? `<h3 class="text-14 highlight mb-2 text-center bg-white">${data.default}</h3>` :
                                _value[0].type === 'ck-editor' ? `<div class="text-14-26 d-inline-block text-right ml-2 mt-0" style="line-height: unset; margin-left: 0.5rem; font-weight: 700">${_value[0].value}</div>` : `<h3 class="text-14 mt-0">${_value[0].value}</h3>` : ''}` +
                            `</div>` +
                            `</td>` +
                            `</tr>`
                        );
                    }
                }
            }).join('')}` +
            `</table>`
        ) : ''}
</body>
`
    const copyToClipboard = () => {
        const type = "text/html";
        const blob = new Blob([copiedPlainText], { type });
        const data = [new ClipboardItem({ [type]: blob })];

        navigator.clipboard.write(data)
            .then(() => {
                toastMessage('Copied to the clipboard');
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });

        window.gtag('event', 'copy_generated_letter', {
            'label': 'Copy Generated letter',
            'screen_name': 'Generated letter',
            'value': 'Copy'
        });
    };

    // Parse HTML to pdfMake JSON

    const isHTML = (str) => {
        const regex = /<\/?[a-z][\s\S]*>/i;
        // //.  console.log('**********------', str, regex.test(str));
        return regex.test(str);
    };

    const downloadClick = () => {
        // //.  console.log('**********------', printContent);
        // generatePDF(printContent)
        const _content = [];

        const addDynamicContent = (letterTemplate, letterBuildData) => {
            if (Array.isArray(letterTemplate) && letterTemplate.length > 0) {
                letterTemplate.forEach((data, index) => {
                    let _value = letterBuildData.filter(obj => obj.title === data.valueOF);
                    let _value1 = letterBuildData.filter(obj => obj.title === data.valueOF1);
                    let _value2 = letterBuildData.filter(obj => obj.title === data?.valueOF2);
                    //.  console.log("data inside _value2:-", _value2);
                    let dynamicText = [];
                    let isvalue = _value.length > 0 ? isHTML(_value[0].value) : false;
                    let isvalue1 = _value1.length > 0 ? isHTML(_value1[0].value) : false;
                    let isvalue2 = _value2.length > 0 ? isHTML(_value2[0].value) : false;
                    let formatedObjectArry = [];
                    let formatedObjectArry1 = [];
                    let formatedObjectArry2 = [];
                    if (isvalue || isvalue1) {

                        let temp = htmlToPdfmake(isvalue ? _value[0].value : isvalue2 ? _value2[0].value : _value1[0].value, { ignoreStyles: ['font-family', 'font', 'margin'] });

                        if (temp.length > 0) {

                            temp = temp.map((item) => {

                                if (typeof item.text === 'string') {
                                    isvalue ? formatedObjectArry.push({
                                        text: item.text + " \n",
                                        style: 'dynamicTextStyle',
                                        bold: item.hasOwnProperty('bold') ? item.bold : false,
                                        nodeName: item.hasOwnProperty('nodeName') ? item.nodeName == 'STRONG' ? item.nodeName : '' : '',
                                    }) : isvalue1 ? formatedObjectArry1.push(item) : formatedObjectArry2.push(item);
                                    return item;
                                } else if (typeof item.text === 'object') {

                                    let _text = {
                                        text: [],
                                        style: 'dynamicTextStyle',
                                        nodeName: '',
                                        fontSize: '',
                                    };
                                    let temptext = item.text.map((item1, index) => {
                                        //.  console.log("dat inside item.text:", item.text);
                                        //.  console.log("dat inside item1:", item1);
                                        if (item1.text.trim() !== '') {
                                            if (isvalue) {
                                                if (index === 0) {
                                                    _text.text.push(item1);
                                                } else {
                                                    _text.text.push(item1);
                                                }
                                            }

                                            return item1;

                                        }
                                    });
                                    if (isvalue)
                                        formatedObjectArry.push(_text);
                                    else if (isvalue1)
                                        formatedObjectArry1.push(_text);
                                    else
                                        formatedObjectArry2.push(_text);
                                    return temptext;
                                }
                            });

                        }
                    }

                    dynamicText = [
                        { text: data.title, style: 'textStyle' },
                        { text: data.leftdescription, style: 'textStyle' },
                        { text: data.valueOF == 'Date' ? data.default : _value.length > 0 ? (_value[0].value === '' ? data.default : isvalue ? formatedObjectArry : _value[0].value) : '', style: 'dynamicTextStyle', bold: true },
                        { text: data.rightdescription, style: 'textStyle' },
                        { text: data.leftdescription1, style: 'textStyle' },
                        { text: _value1.length > 0 ? (_value1[0].value === '' ? data.default1 : isvalue1 ? formatedObjectArry1 : _value1[0].value) : '', style: 'dynamicTextStyle', bold: true },
                        { text: data.rightdescription1, style: 'textStyle' },
                        { text: data.leftdescription2, style: 'textStyle' },
                        { text: _value2.length > 0 ? (_value2[0].value === '' ? data.default2 : isvalue2 ? formatedObjectArry2 : _value2[0].value) : '', style: 'dynamicTextStyle', bold: true },
                        { text: data.rightdescription2, style: 'textStyle' }

                    ].filter(item => item.text); // Remove empty text entries

                    if (index < location.state?.maxLeftIndex && data.alignment !== 'left' && data.alignment !== 'side-right') {
                        _content.push({
                            columns: [
                                {
                                    width: '50%',
                                    text: '',
                                    margin: [0, 5, 0, 0],
                                    fillColor: '#fafafa',
                                    padding: [30, 0, 30, 0]
                                },
                                {
                                    width: '50%',
                                    text: dynamicText,
                                    alignment: 'right',
                                    margin: [0, 5, 0, 0],
                                    fillColor: '#fafafa',
                                    padding: [30, 0, 30, 0]
                                }
                            ]
                        });
                    } else {
                        _content.push({
                            text: dynamicText,
                            style: 'dynamicTextStyle',
                            margin: [0, 5, 0, 0], // Margin top of 15px to separate each block
                            fillColor: '#fafafa',
                            padding: [30, 0, 30, 0] // Internal padding
                        });
                    }

                });
            }
        };




        addDynamicContent(letterTemplate, letterBuildData);
        // //.  console.log('**********------', _content);
        const docDefinition = {
            pageMargins: [90, 80, 80, 50], // [left, top, right, bottom]
            header: (currentPage, pageCount) => {
                return {
                    image: EmailTemplateBanner, // Base64 encoded image
                    width: 595.28, // Full page width for A4 size
                    margin: [0, 0, 20, 20], // Adjust margins as needed
                };
            },
            content: _content,
            styles: {
                textStyle: {
                    fontSize: 11,
                    lineHeight: 14.52 / 11, // Adjust line height relative to font size
                    fontWeight: 400,
                    margin: [5, 15, 0, 0], // Margin top of 15px to separate each block
                    wordBreak: 'break-word'
                },
                innerTextStyle: {
                    fontSize: 11,
                    lineHeight: 14.52 / 11, // Adjust line height relative to font size
                    fontWeight: 400,
                    margin: [0, 5, 0, 5], // Margin top of 15px to separate each block
                    wordBreak: 'break-word'
                },
                dynamicTextStyle: {
                    fontSize: 11,
                    lineHeight: 14.52 / 11, // Adjust line height relative to font size
                    fontWeight: 700,
                    wordBreak: 'break-word',
                    margin: [0, 5, 0, 0] // Margin top of 15px to separate each block
                }

            }
        };

        pdfMake.createPdf(docDefinition).download('Generated-letter.pdf');

        window.gtag('event', 'download_generated_letter', {
            'label': 'Download Generated letter',
            'screen_name': 'Generated letter',
            'value': 'Download'
        });

    }

    const print = () => {
        // const printWindow = window.open('', '', 'width=800,height=600');
        // printWindow.document.write(htmlContent);
        // printWindow.document.close();
        // printWindow.focus();
        // printWindow.print();
        // printWindow.close();


        const printWindow = window.open('', '', 'width=800,height=600');
        printWindow.document.write(printContent);
        printWindow.document.title = 'Generated-letter-plan'
        printWindow.document.close();
        printWindow.focus();

        const images = printWindow.document.images;
        const totalImages = images.length;
        let loadedImages = 0;

        if (totalImages === 0) {
            printWindow.print();
            printWindow.close();
        } else {
            for (let i = 0; i < totalImages; i++) {
                // eslint-disable-next-line no-loop-func
                images[i].onload = () => {
                    loadedImages++;
                    if (loadedImages === totalImages) {
                        printWindow.print();
                        printWindow.onafterprint = () => {	

                            printWindow.close();
                            };
                    }
                };
                images[i].onerror = () => {
                    loadedImages++;
                    if (loadedImages === totalImages) {
                        printWindow.print();
                        printWindow.close();
                    }
                };
            }
        }

        window.gtag('event', 'print_generated_letter', {
            'label': 'Print Generated letter',
            'screen_name': 'Generated letter',
            'value': 'Print'
        });
    }

    useEffect(() => {

        if (location.state !== null && location.state.letterBuild !== undefined) {
            setLetterBuilderData(location.state.letterBuild.sort((a, b) => a.position - b.position));
        }

        if (location.state !== null && location.state.letterTemplate !== undefined) {
            setLetterTemplate(location.state.letterTemplate);
        }

        if (location.state !== null && location.state.information !== undefined) {
            setInformationContent(location.state.information);
        }

    }, [location])

    useEffect(() => { }, [letterBuildData])

    useEffect(() => {
        dispatch(fetchHomepageData());
    }, [])

    const emailClick = () => {
        setEmailModal(true);
    }

    useEffect(() => {
        if (externalLinkData.isSuccess && externalLinkData.data !== null) {
            setExternalLinkList(externalLinkData.data.success.data.contactExternalSites);
        }
    }, [externalLinkData])

    return (
        <div>
             {ACCESSIBILITY_FEATURE_ENABLED ? 
      <FontResizeWidget minSize={14} maxSize={32} step={2} defaultSize={16} />: null}
            <Helmet>
                <title>Get advice | Generated letter | My Housing Gateway</title>
                <meta property="og:title" content="Get advice | Generated letter | My Housing Gateway" />
                <meta name="twitter:card" content="Get advice | Generated letter | My Housing Gateway" />
                <meta description='If you have an issue with your rented property we can help by creating an action plan for you. This will explain the steps you should take in order to resolve your issue.' />
            </Helmet>
            <div className='letterBuilderPage generated-letter'>
                <div>
                    <div className='bg-white container'>
                        <BackToAction link={"/get-advice"} />
                        <h3 className='title-26 black'>Your generated letter</h3>
                        <div className='d-flex flex-column flex-md-row justify-content-between letter-header'>
                            <p className='text-18 '>We have generated you a letter to use based on our template, please review below and export to use as required.</p>
                            <ul className='d-flex justify-content-end d-none d-md-flex letter-button-container '>
                                <li><Button className='smallButton text-16-bold without-arrow' onClick={() => downloadClick()}>Download</Button></li>
                                <li><Button className='smallButton text-16-bold without-arrow' onClick={() => copyToClipboard()}>Copy</Button></li>
                                <li><Button className='smallButton text-16-bold without-arrow' onClick={() => emailClick()}>Email</Button></li>
                                <li><Button className='smallButton text-16-bold without-arrow' onClick={() => print()}>Print</Button></li>
                            </ul>
                            {copied && <span style={{ color: 'green' }}>Copied to clipboard!</span>}
                        </div>
                        {/* <div className='d-block'>
                            {informationContent.title !== '' && informationContent.description !== '' && informationContent.postion === 'top' ? (
                                <InformationContent title={informationContent.title} description={informationContent.description} />
                            ) : null}
                        </div> */}
                        <div className='letterEditor-container'>
                            {Array.isArray(letterTemplate) && letterTemplate.length > 0 ? (
                                <div id="letterEditor-viewer" className='letter-content letterEditor-viewer'>

                                    {letterTemplate.map((data, index) => {

                                        let _value = letterBuildData.filter(obj => obj.title == data.valueOF);

                                        if (index < location.state?.maxLeftIndex && data.alignment !== 'left' && data.alignment !== 'side-right') {
                                            return (<div className='d-flex justify-content-between mb-2'>
                                                <p className='text-14 col-6'>{data.title}</p>
                                                {_value.length > 0 ? _value[0].value == '' ? <h3 className='text-14-26 highlight mb-0 text-center bg-white' style={{ textAlign: 'right', marginTop: 0, }}>{data.default}</h3> :
                                                    <h3 className='text-14-26 text-right col-5 ' style={{ textAlign: 'right', marginTop: 0, minWidth: '9.375rem', marginBottom: 0, paddingBottom: 0 }}>{_value[0].value}</h3> : data.valueOF == 'Date' ? <h3 className='text-14-26 text-right col-3 ' style={{ textAlign: 'right', marginTop: 0, marginBottom: 0, paddingBottom: 0 }}>{data.default}</h3> : <p></p>}</div>)
                                        } else {
                                            if (data?.default2 !== '') {

                                                let _value1 = letterBuildData.filter(obj => obj.title == data.valueOF1);
                                                let _value2 = letterBuildData.filter(obj => obj.title == data.valueOF2);

                                                return <div className='align-items-start mt-3 mb-3'>
                                                    <p className='text-14 mb-0' style={{ fontWeight: 400, wordBreak: 'break-word' }}>{data.leftdescription}
                                                        {_value.length > 0 ? _value[0].value == '' ? <h3 className='text-14-26 d-inline-block highlight ml-2 mr-2 text-center bg-white mb-0'>{data.default}</h3> :
                                                            _value[0].type === 'ck-editor' ? <strong className='text-14-26 d-inline-block text-right ml-2' style={{ lineHeight: 'unset', marginLeft: '0.1rem', marginRight: '0.1rem', paddingBottom: 0, marginBottom: 0, marginTop: 0 }} dangerouslySetInnerHTML={{ __html: _value[0].value }} /> :

                                                                <strong className='text-14-26 d-inline-block text-right  ml-2' style={{ lineHeight: 'unset', marginLeft: '0.1rem', marginRight: '0.1rem', paddingBottom: 0, marginBottom: 0, marginTop: 0 }}>{_value[0].value}</strong> : data.valueOF === 'Date' ? <strong className='text-14-26 ml-2 d-inline-block text-right col-3'>{data.default}</strong> : null}
                                                        {data.rightdescription}
                                                        {data.leftdescription1}

                                                        {_value1.length > 0 ? _value1[0].value == '' ? <h3 className='text-14-26 d-inline-block highlight ml-2 text-center bg-white mb-0'>{data.default1}</h3> :
                                                            _value1[0].type === 'ck-editor' ? <strong className='text-14-26 d-inline-block text-right ml-2' style={{ lineHeight: 'unset', marginLeft: '0.1rem', marginRight: '0.1rem', paddingBottom: 0, marginBottom: 0, marginTop: 0 }} dangerouslySetInnerHTML={{ __html: _value1[0].value }} /> :

                                                                <strong className='text-14-26 d-inline-block text-right  ml-2' style={{ lineHeight: 'unset', marginLeft: '0.1rem', marginRight: '0.1rem', paddingBottom: 0, marginBottom: 0, marginTop: 0 }}>{_value1[0].value}</strong> : data.valueOF1 === 'Date' ? <strong className='text-14-26 ml-2 d-inline-block text-right col-3'>{data.default1}</strong> : null}
                                                        {data.rightdescription1}
                                                        {data.leftdescription2}

                                                        {_value2.length > 0 ? _value2[0].value == '' ? <h3 className='text-14-26 d-inline-block highlight ml-2 text-center bg-white mb-0'>{data.default2}</h3> :
                                                            _value2[0].type === 'ck-editor' ? <strong className='text-14-26 d-inline-block text-right ml-2' style={{ lineHeight: 'unset', marginLeft: '0.1rem', marginRight: '0.1rem', paddingBottom: 0, marginBottom: 0, marginTop: 0 }} dangerouslySetInnerHTML={{ __html: _value2[0].value }} /> :

                                                                <strong className='text-14-26 d-inline-block text-right  ml-2' style={{ lineHeight: 'unset', marginLeft: '0.1rem', marginRight: '0.1rem', paddingBottom: 0, marginBottom: 0, marginTop: 0 }}>{_value2[0].value}</strong> : data.valueOF2 === 'Date' ? <strong className='text-14-26 ml-2 d-inline-block text-right col-3'>{data.default2}</strong> : null}
                                                        {data.rightdescription2}

                                                    </p>
                                                </div>
                                            }
                                            else if (data.default1 !== '') {

                                                let _value1 = letterBuildData.filter(obj => obj.title == data.valueOF1);

                                                return <div className='align-items-start mt-3 mb-3'>
                                                    <p className='text-14 mb-0' style={{ fontWeight: 400, wordBreak: 'break-word' }}>{data.leftdescription}
                                                        {_value.length > 0 ? _value[0].value == '' ? <h3 className='text-14-26 d-inline-block highlight ml-2 mr-2 text-center bg-white mb-0'>{data.default}</h3> :
                                                            _value[0].type === 'ck-editor' ? <strong className='text-14-26 d-inline-block text-right ml-2' style={{ lineHeight: 'unset', marginLeft: '0.1rem', marginRight: '0.1rem', paddingBottom: 0, marginBottom: 0, marginTop: 0 }} dangerouslySetInnerHTML={{ __html: _value[0].value }} /> :

                                                                <strong className='text-14-26 d-inline-block text-right  ml-2' style={{ lineHeight: 'unset', marginLeft: '0.1rem', marginRight: '0.1rem', paddingBottom: 0, marginBottom: 0, marginTop: 0 }}>{_value[0].value}</strong> : data.valueOF === 'Date' ? <strong className='text-14-26 ml-2 d-inline-block text-right col-3'>{data.default}</strong> : null}
                                                        {data.rightdescription}
                                                        {data.leftdescription1}

                                                        {_value1.length > 0 ? _value1[0].value == '' ? <h3 className='text-14-26 d-inline-block highlight ml-2 text-center bg-white mb-0'>{data.default1}</h3> :
                                                            _value1[0].type === 'ck-editor' ? <strong className='text-14-26 d-inline-block text-right ml-2' style={{ lineHeight: 'unset', marginLeft: '0.1rem', marginRight: '0.1rem', paddingBottom: 0, marginBottom: 0, marginTop: 0 }} dangerouslySetInnerHTML={{ __html: _value1[0].value }} /> :

                                                                <strong className='text-14-26 d-inline-block text-right  ml-2' style={{ lineHeight: 'unset', marginLeft: '0.1rem', marginRight: '0.1rem', paddingBottom: 0, marginBottom: 0, marginTop: 0 }}>{_value1[0].value}</strong> : data.valueOF1 === 'Date' ? <strong className='text-14-26 ml-2 d-inline-block text-right col-3'>{data.default1}</strong> : null}
                                                        {data.rightdescription1}

                                                    </p>
                                                </div>
                                            } else if (data.alignment === 'side-right') {
                                                return <div className='align-items-start mt-3 mb-3'>

                                                    <p className='text-14 mb-0' style={{ fontWeight: 400 }}>{data.leftdescription}
                                                        {_value.length > 0 ? _value[0].value == '' ? <h3 className='text-14-26 d-inline-block highlight ml-2 text-center bg-white mb-0'>{data.default}</h3> :
                                                            _value[0].type === 'ck-editor' ? <strong className='text-14-26 d-inline-block text-right ml-2' style={{ lineHeight: 'unset', marginLeft: '0.1rem', marginTop: 0, marginBottom: 0 }} dangerouslySetInnerHTML={{ __html: _value[0].value }} /> :

                                                                <strong className='text-14-26 d-inline-block text-right  ml-2' style={{ lineHeight: 'unset', marginLeft: '0.1rem', paddingBottom: 0, marginBottom: 0, marginTop: 0 }}>{_value[0].value}</strong> : data.valueOF === 'Date' ? <strong className='text-14-26 ml-2 d-inline-block text-right col-3'>{data.default}</strong> : null}
                                                    </p>
                                                    {data.rightdescription !== '' ? <p className='text-14 mb-0 d-flex' style={{ fontWeight: 400 }}>{data.rightdescription}</p> : null}
                                                </div>
                                            } else if (data.alignment === 'center') {
                                                return <div className='align-items-start mt-3 mb-3'>

                                                    <p className='text-14 mb-0' style={{ fontWeight: 400 }}>{data.leftdescription}
                                                        {_value.length > 0 ? _value[0].value == '' ? <h3 className='text-14-26 d-inline-block highlight ml-2 text-center bg-white'>{data.default}</h3> :
                                                            _value[0].type === 'ck-editor' ? <strong className='text-14-26 d-inline-block text-right ml-2' style={{ lineHeight: 'unset', marginLeft: '0.1rem' }} dangerouslySetInnerHTML={{ __html: _value[0].value }} /> : <strong className='text-14-26 d-inline-block text-right  ml-2' style={{ lineHeight: 'unset', marginLeft: '0.1rem', marginRight: '0.1rem', paddingBottom: 0, marginBottom: 0, marginTop: 0 }}>{_value[0].value}</strong> : data.valueOF === 'Date' ? <h3 className='text-14-26 ml-2 d-inline-block text-right col-3'>{data.default}</h3> : null}
                                                        {data.rightdescription !== '' ? data.rightdescription : null}
                                                    </p>

                                                </div>
                                            } else {
                                                return (
                                                    <div className={data.title === '' ? 'd-flex flex-column align-items-start' : 'd-flex flex-column align-items-start mt-3'}>
                                                        <p className="text-14-normal mb-2">{data.title}</p>
                                                        {_value.length > 0 ? _value[0].value == '' ? <h3 className='text-14 highlight mb-2 text-center bg-white'>{data.default}</h3> :
                                                            _value[0].type == 'ck-editor' ? <h3 className='text-14-26 d-inline-block text-right ml-2' style={{ lineHeight: 'unset', marginLeft: '0.1rem', marginTop: '0.5rem', paddingBottom: 0, marginBottom: 0 }} dangerouslySetInnerHTML={{ __html: _value[0].value }} /> : <h3 className='text-14 mt-0 mb-0 pb-0'>{_value[0].value}</h3> : null}
                                                    </div>
                                                )
                                            }
                                        }
                                    })}
                                </div>
                            ) : null}
                        </div>
                        <div className='letter-bottom'>
                            <div className='d-block'>
                                {informationContent.title !== '' && informationContent.description !== '' && informationContent.postion === 'bottom' ? (
                                    <InformationContent title={informationContent.title} description={informationContent.description} />
                                ) : null}
                            </div>
                            <div>
                                
                                <div className='d-flex justify-content-between letter-button-container mt-3 d-md-none'>
                                    <Button className='smallButton without-arrow' onClick={() => emailClick()} style={{ flexBasis: '22%', margin: 0 }}>Email</Button>
                                    <Button className='smallButton without-arrow' onClick={() => print()} style={{ flexBasis: '22%', margin: 0 }}>Print</Button>
                                    <Button className='smallButton without-arrow' onClick={() => copyToClipboard()} style={{ flexBasis: '22%', margin: 0 }}>Copy</Button>
                                    <Button className='smallButton without-arrow' onClick={() => downloadClick()} style={{ flexBasis: '22%', margin: 0 }}>Download</Button>
                                </div>
                                <div className='d-flex justify-content-between action-buttons'>
                                    <Button className='back button without-arrow' onClick={() => navigate('/get-advice/letter-builder', { state: { path: 'letter-generated' } })}>Back</Button>
                                    <Button className='button without-arrow action-back' onClick={() => navigate('/get-advice/action-plan')}>Back to plan</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {externalLinkList.length > 0 ? (
                        <GetHelpSection title={"Where can I get help?"} description={"For general information, browse by topic instead."} list={externalLinkList} />
                    ) : null}
                </div>
            </div>

            <ShareModal
                show={shareEnable}
                onHide={() => setShareEnable(false)}
            />

            <EmailModal
                show={emailModal}
                type={"letter-builder"}
                onHide={() => setEmailModal(false)}
                htmlContent={htmlContent}
                title={"Please provide your email address so we can send you the letter"}
            />
            <ToastContainer />
        </div>
    )
}

export default GeneratedLetter
