import React, { useEffect, useState } from 'react';
import { Chart, CommonPorblems, HeroSection, Homelessness, Loader, Cards } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHomepageData, resetHomepageData } from './store/actions';
import { setData } from '../../utils/storage';
import { Helmet } from 'react-helmet';
import FontResizeWidget from '../../components/FontResizeWidget';
import { ACCESSIBILITY_FEATURE_ENABLED } from '../../config';
import { Link } from 'react-router-dom';
import { parseHelmetTags } from '../../utils/utility';
const Homepage = () => {

  const [dropdownOption, setDropdDownOption] = useState([]);
  const [commonCards, setCommonCards] = useState();
  const [homelessCards, setHomelessCards] = useState([]);
  const [helmetTag, setHelmetTag] = useState('');
  const [topicsData, setTopicData] = useState([]);
  const dispatch = useDispatch();
  const homepageData = useSelector(state => state.home);
  const [titleTag, setTitleTag] = useState();
  const [metaTags, setMetaTags] = useState();
  const [heroSection,setHeroSection] = useState();
  const [commonProblems,setCommonProblems] = useState();
  const [homelessness,setHomelessness] = useState();
  const [diagramSection,setDiagramSection] = useState();
  const [generalInformation,setGeneralInformation] = useState();
  const [personalInformation,setPersonalInformation] = useState();

  useEffect(() => {
    dispatch(fetchHomepageData());
  }, [])


  useEffect(() => {
    if (homepageData.isSuccess && homepageData.data !== null) {
      let rentingType = homepageData.data.success.data.rentingTypes;
      let commonCardsList = homepageData.data.success.data.commonProblems;
      let homelessCardsList = homepageData.data.success.data.homelessnessExternalSites;
      for (let rentingData in rentingType) {
        let obj = {
          id: rentingData,
          value: rentingType[rentingData]
        }
        const objLen = dropdownOption.filter((item) => item.id === obj.id);

        if (!objLen.length > 0) {
          setDropdDownOption((current) => [...current, obj]);
        }
      }
      setHelmetTag(homepageData.data.success.data.homeMetaData);
      setCommonCards(commonCardsList);
      setHomelessCards(homelessCardsList);
      setTopicData(homepageData.data.success.data.topics);
      setData('homepageData', homepageData.data.success.data);
      setHomelessness(homepageData.data.success.data.homeData.homelessness_section);
      setCommonProblems(homepageData.data.success.data.homeData.common_problem_section);
      setHeroSection(homepageData.data.success.data.homeData.hero_section);
      setDiagramSection(homepageData.data.success.data.homeData.diagram_section);
      setGeneralInformation(homepageData.data.success.data.homeData.general_info_section);
      setPersonalInformation(homepageData.data.success.data.homeData.personalised_plan_section);

      dispatch(resetHomepageData())
    } else if (homepageData.isError) {
      dispatch(resetHomepageData())
    }
  }, [homepageData])

  useEffect(() => {
    if (helmetTag !== '') {
      // console.log("helmetTag:-", helmetTag);  
      const {titleTag, metaTags} = parseHelmetTags(helmetTag);
      setTitleTag(titleTag);
      setMetaTags(metaTags);
    }
  }
    , [helmetTag])
console.log("homepageData:-",heroSection);
  return (
    <div>
       { ACCESSIBILITY_FEATURE_ENABLED ? 
      <FontResizeWidget minSize={14} maxSize={32} step={2} defaultSize={16} />: null}
      { helmetTag !== '' ?  <Helmet>{titleTag} {metaTags}</Helmet>  :  <Helmet>
        <title>Home | My Housing Gateway</title>
         </Helmet>}
      <div className="homepage d-flex flex-column" style={{ width: '100%' }}>

        {homepageData.isFetching ? (
          <Loader message={"Loading....."} />
        ) : homepageData.isSuccess ? (
          <div>
            <HeroSection title={heroSection?.title} page={'homepage'} description={heroSection?.subcopy} link={'link'} sub={'sub'} />
            {commonCards !== undefined ? Object.keys(commonCards).length > 0 ? (
              <CommonPorblems dropdown={dropdownOption} commonCards={commonCards} title={commonProblems.title} description={commonProblems.subcopy} />
            ) : null : null}
            <Chart dropdown={dropdownOption} title={diagramSection?.title} personal_Title = {personalInformation?.title} personal_description={personalInformation?.subcopy} />
            {Array.isArray(homelessCards) && homelessCards.length > 0 ? (
              <Homelessness homelessCards={homelessCards} title={homelessness?.title} description={homelessness?.subcopy} />
            ) : null}
            <div className='container start-topics'>
              {topicsData.length > 0 ? (

                <div className='card-container '>
                  <h3 className='title lightGrey mb-2'>{generalInformation?.title}</h3>
                  <p className='text-normal lightGrey inter-font mb-2 col-12 col-md-11 '>{generalInformation?.subcopy}</p>
                  <ul className='d-flex justify-content-start'>
                    {topicsData.map((item) => {
                      return <Cards type="topic" title={item.topicTitle} link={item.topicSlug} id={item.topicId} />
                    })}
                  </ul>
                  {/* <GetHelpSection description={"For general information, browse by topic instead"} /> */}
                </div>
              ) : null}
              <div className="mt-4">
              <Link className="button" to="/faq">Frequently Asked Questions</Link>
              </div>
            </div>

          </div>
        ) : null}

      </div>

    </div>
  )
}

export default Homepage
