import React, { useEffect, useState } from 'react'
import { AboutContent, GetHelpSection, HeroSection } from '../components'
import { useDispatch, useSelector } from 'react-redux'
import { fetchHomepageData } from './homepage/store/actions';
import { getData } from '../utils/storage';
import { Helmet } from 'react-helmet';
import FontResizeWidget from '../components/FontResizeWidget';
import { ACCESSIBILITY_FEATURE_ENABLED } from '../config';

const About = () => {

  const externalSiteData = useSelector((state) => state.home);
  const [externalSiteList, setExternalSiteList] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {

    getData('homepageData').then((res) => {
      if (res !== undefined) {
        setExternalSiteList(res.aboutExternalSites)
      } else {
        dispatch(fetchHomepageData());
      }
    })

  }, [])

  useEffect(() => {
    if (externalSiteData.isSuccess && externalSiteData.data !== null) {
      setExternalSiteList(externalSiteData.data.success.data.aboutExternalSites);
    }
  }, [externalSiteData])

  return (
    <div className='about' >
    
      {ACCESSIBILITY_FEATURE_ENABLED ? 
      <FontResizeWidget minSize={14} maxSize={32} step={2} defaultSize={16} />: null}
      <Helmet>
        <title>About | My Housing Gateway</title>
        <meta property="og:title" content="About | My Housing Gateway" />
                <meta name="twitter:card" content="About | My Housing Gateway" />
        <meta description='If you have an issue with your rented property we can help by creating an action plan for you. This will explain the steps you should take in order to resolve your issue.' />
      </Helmet>
      <HeroSection title={'About the platform'} page={'about'} />
      <AboutContent bigHero={externalSiteList.length === 0 ? true : false} />
      {externalSiteList.length > 0 ? (
        <GetHelpSection description={"For more information and other associated work"} list={externalSiteList} />
      ) : null}
    </div>
  )
}

export default About
