import React, { useEffect, useState } from 'react';
import { BackToAction, GetHelpSection, LetterContainer } from '../components';
import { getData } from '../utils/storage';
import { Helmet } from 'react-helmet';
import FontResizeWidget from '../components/FontResizeWidget';
import { ACCESSIBILITY_FEATURE_ENABLED } from '../config';
const LetterBuilderPage = () => {
  const [externalLinksData, setExternalLinksData] = useState([]);
  useEffect(() => {

    const getExternalLinksData = async () => {
      const response = await getData('generalExternalSites');
      // const data = await response.json();
      setExternalLinksData(response);
    }
    getExternalLinksData()
  }, [])

  useEffect(() => {
    // console.log(externalLinksData);
  }, [externalLinksData])
  return (
    <div>
       {ACCESSIBILITY_FEATURE_ENABLED ? 
      <FontResizeWidget minSize={14} maxSize={32} step={2} defaultSize={16} />: null}
      <Helmet>
        <title>Get Advice | Letter Builder | My Housing Gateway</title>
        <meta property="og:title" content="Get Advice | Letter Builder | My Housing Gateway" />
                <meta name="twitter:card" content="Get Advice | Letter Builder | My Housing Gateway" />
        <meta description='If you have an issue with your rented property we can help by creating an action plan for you. This will explain the steps you should take in order to resolve your issue.' />
      </Helmet>
      <div className="letterBuilderPage">
        <div>
          <LetterContainer />
          <GetHelpSection title='Where can I get help?' description='If you need alternative help or advice, please visit one of these websites:' list={externalLinksData} />
        </div>
      </div>
    </div>
  )
}
export default LetterBuilderPage;
