import React from "react";
import App from "../App";

import {
  
  Homepage,
  Topics,
  LetterBuilderPage,
  About,
  StartQuestion,
  TelephoneSupportPage,
  TelephoneSupportPrompts,
  QuestionsSet,
  Contact,
  Error,
  ActionPlan,
  PrivacyPolicyPage,
  CookiePolicyPage,
  GeneratedLetter
} from "../screens";
import FAQ from "../screens/faq";



const Router = [
  
  
{
  path: "/",
  element: <App><Homepage /></App>,
},

{
  path: "/topics",
  element: <App><Topics /></App>,
},
{
  path: "/topics/:subtopics",
  element: <App><Topics /></App>,
},
{
  path: "/topics/:subtopics/:filter",
  element: <App><Topics /></App>,
},

{
  path: "/get-advice/letter-builder",
  element: <App><LetterBuilderPage /></App>,
},

{
  path: "/about",
  element: <App><About /></App>,
},
{
  path: "/faq/",
  element: <App><FAQ /></App>,
},

{
  path: "/faq/:title",
  element: <App><FAQ /></App>,
},



{
  path: "/get-advice",
  element: <App><StartQuestion /></App>,
  
},


{
  path: "/get-advice/questions",
  element: <App><QuestionsSet /></App>,
},

{
  path:"/get-advice/telephone-support",
  element:<App><TelephoneSupportPage/></App>,
}
,
{
  path:"/get-advice/telephone-prompts",
  element:<App><TelephoneSupportPrompts/></App>,
},
// {
//   path:"/contact",
//   element:<App><Contact /></App>,
// },
{
  path:"/emergency",
  element:<App><Contact /></App>,
},
{
  path:"/get-advice/action-plan",
  element: <App><ActionPlan /> </App>
},
{
  path:"/get-advice/generated-letter",
  element: <App><GeneratedLetter /> </App>
},
{
  path:"*",
  element:<App><Error /></App>,
},
{
  path:"/privacy-policy",
  element:<App><PrivacyPolicyPage/></App>,
},
// {
//   path:"/cookie-policy",
//   element:<App><CookiePolicyPage/></App>,
// }


];

export default Router;
