export const ENVIRONMENT = process.env.REACT_APP_ENV;
export const APP_VERSION = process.env.REACT_APP_VERSION;

let auth_headers = {
    'X-Authorization': 'TuGSMpwI6FYBnocs0LVykUpnMKCapcLA',
    'Content-Type': 'application/json',
  
};

// if (ENVIRONMENT === 'production') {
//     api_endpoint = process.env.REACT_APP_API_ENDPOINT;
//     // auth_headers = { 'X-Authorization': process.env.REACT_APP_X_AUTHORIZATION, 'Content-Type': 'application/json' };

//     baseUrl = process.env.REACT_APP_BASE_URL;
// } else if (ENVIRONMENT === 'staging') {
//     api_endpoint = process.env.REACT_APP_API_ENDPOINT;
//     // auth_headers = { 'X-Authorization': process.env.REACT_APP_X_AUTHORIZATION, 'Content-Type': 'application/json' };

//     baseUrl = process.env.REACT_APP_BASE_URL;
// } else if (ENVIRONMENT === 'development') {
//     api_endpoint = process.env.REACT_APP_API_ENDPOINT;
//     // auth_headers = { 'X-Authorization': process.env.REACT_APP_X_AUTHORIZATION, 'Content-Type': 'application/json' };

//     baseUrl = process.env.REACT_APP_BASE_URL;
// }else if(ENVIRONMENT === 'uat'){
//     api_endpoint = process.env.REACT_APP_API_ENDPOINT;
//     baseUrl = process.env.REACT_APP_BASE_URL;
// }

export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;;
export const AUTH_HEADERS = auth_headers;
export const BaseUrl = process.env.REACT_APP_BASE_URL;
export const gaTracking = process.env.REACT_APP_GA4_ID;
// to make the accessibility feature enabled or disabled flag true or false is set in the .env file
export const ACCESSIBILITY_FEATURE_ENABLED  = process.env.REACT_APP_ACCESSIBILITY_FEATURE === 'true' ? true : false; // true or false 
