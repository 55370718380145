import React from 'react'
import Cards from '../cards'

const Homelessness = (props) => {
   

    return (

        <div className='bg-light homelessness' key={'homelessCards'}>
            <div className='container'>
                <h3 className='title lightGrey'>{props?.title}</h3>
                <p className='text-normal lightGrey inter-font mb-2'>{props?.description}</p>
                <ul className="card-container d-flex justify-content-between flex-wrap justify-content-md-start">
                    {Array.isArray(props.homelessCards) && props.homelessCards.length > 0 ? (
                        <>
                            {props.homelessCards.map((item, index) => {
                                const id = `card-${index}`; // Use index-based unique ID for better performance
                                return <Cards type='site' title={item.externalSiteTitle} link={item.externalSiteLink} id={id} />
                            })}
                        </>
                    ) : null}
                   
                </ul>
            </div>
        </div>
    )
}

export default Homelessness
