import React from "react";

export const isEmpty = (obj) => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export const UIAlert = (type, response) => {
    alert(JSON.stringify(response))
}

export const parseHelmetTags = (htmlString = "") => {
    // console.log("htmlString:-",htmlString);
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
//   console.log(doc);
    let titleTag = null;
    const metaTags = [];
  
    Array.from(doc.head.childNodes).forEach((node, index) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        const tagName = node.tagName.toLowerCase();
        const attributes = {};
  
        Array.from(node.attributes).forEach((attr) => {
          attributes[attr.name] = attr.value;
        });
  
        if (tagName === "title") {
          titleTag = <title key={index}>{node.textContent}</title>;
        } else if (tagName === "meta") {
          metaTags.push(<meta key={index} {...attributes} />);
        }
      }
    });
  
    return { titleTag, metaTags };
    // return htmlString
    //   .trim() // Remove extra spaces or new lines
    //   .split("\n") // Split by line
    //   .map((tag, index) => {
    //     tag = tag.trim(); // Clean up any extra spaces
  
    //     if (tag.startsWith("<title>")) {
    //       const title = tag.match(/<title>(.*?)<\/title>/);
    //       return title ? <title key={index}>{title[1]}</title> : null;
    //     } else if (tag.startsWith("<meta")) {
    //       const match = tag.match(/<meta\s+([^>]+)\/?>/);
    //       if (match) {
    //         const attributes = match[1]
    //           .split(/\s+/)
    //           .reduce((acc, attr) => {
    //             const [key, value] = attr.split("=");
    //             acc[key] = value?.replace(/['"]/g, ""); // Remove quotes
    //             return acc;
    //           }, {});
    //         return <meta key={index} {...attributes} />;
    //       }
    //     }
    //     return null;
    //   })
    //   .filter(Boolean); // Remove null values
  };
