import React, { useEffect, useState } from 'react'
import { Breadcrumb, Infoasking, Loader, NoticeCard, QuestionForm } from '../../components'
import { useDispatch, useSelector } from 'react-redux';
import { fetchQuestionData, resetQuestionData } from './store/actions';
import { Helmet } from 'react-helmet';
import FontResizeWidget from '../../components/FontResizeWidget';
import { ACCESSIBILITY_FEATURE_ENABLED } from '../../config';
import { useLocation } from 'react-router-dom';

const QuestionsSet = (props) => {

  const location = useLocation();
  const [noticeDescription, setNoticeDescription] = useState("");
  const [questionInfoText, setQuestionInfoText] = useState("");
  const [questionsData, setQuestionsData] = useState("");
  const [actionPlan, setActionPlan] = useState(false);
  const [postCode, setPostCode] = useState(false);
  const questionDataFetch = useSelector((state) => state.questionSet);
//.  console.log("data inside props:-",location.state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchQuestionData());
  }, [])

  useEffect(() => {
    if (questionDataFetch.isSuccess && questionDataFetch.data !== null) {
      setQuestionsData(questionDataFetch.data.success.data.questions);
      setActionPlan(questionDataFetch.data.success.data.actionStepQuestionEnabled);
      setPostCode(questionDataFetch.data.success.data.postcodeQuestionEnabled);
      dispatch(resetQuestionData());
    } else if (questionDataFetch.isError) {
      dispatch(resetQuestionData());
    }

  }, [questionDataFetch])




  const noticeCardDescription = (desciption, asking) => {
    setNoticeDescription(desciption);
    setQuestionInfoText(asking);
  }

  useEffect(() => {
  }, [questionsData])

  return (
    <div className='d-flex flex-grow-1'>
       {ACCESSIBILITY_FEATURE_ENABLED ? 
      <FontResizeWidget minSize={14} maxSize={32} step={2} defaultSize={16} />: null}
      <Helmet>
        <title>Questions | Get advice | My Housing Gateway</title>
        <meta property="og:title" content="Questions | Get advice | My Housing Gateway" />
                <meta name="twitter:card" content="Questions | Get advice | My Housing Gateway" />
        <meta description='If you have an issue with your rented property we can help by creating an action plan for you. This will explain the steps you should take in order to resolve your issue.' />
      </Helmet>
      {questionDataFetch.isFetching ? (
        <Loader message={"Loading....."} />
      ) : questionDataFetch.isSuccess && questionsData.length > 0 ? (
        <div className='start-questions'>
          <div className='container '>
            <div className='col-12 col-md-8 mb-0 breadcrumb-container'>
              <Breadcrumb />
            </div>

            {questionsData ? (
              <>
                <div className='d-flex start justify-content-between flex-column flex-md-row'>
                  <div className='col-12 col-md-8'>
                    
                    <QuestionForm noticeDescription={noticeCardDescription} 
                    question={questionsData} actionPlanQuestion={actionPlan} 
                    postCode={postCode} formResponseData={location.state?.actionData || null}
                    questionID={location.state?.questionId || null}/>
                  </div>
                  <div className='col-12 col-md-3'>
                    <NoticeCard title={"Did you know?"} description={noticeDescription} />
                  </div>
                </div>
                {questionInfoText ? (
                  <Infoasking askingText={questionInfoText} />
                ) : null}
              </>
            ) : null}
          </div>

        </div>
      ) : questionsData.length === 0 && questionDataFetch.isSuccess ?
        <div className='container'>
          <p className='text-24 mt-4'>No questions found</p>
        </div>
        : null}
    </div>
  )
}

export default QuestionsSet
