import React, { useEffect, useState } from 'react'
import DropdownContainer from '../dropdown'
import Cards from '../cards';
import { setData } from '../../utils/storage';

const CommonPorblems = (props) => {

    const [filterData, setFilterData] = useState(props.commonCards ? props.commonCards[1] : []);
    const [dropdownVal, setDropDownVal] = useState();
    const [dropdownId, setDropDownId] = useState(3);

    const onDropDownClick = (val, id) => {
        setDropDownVal(val);
        setDropDownId(id);
    }

    useEffect(() => {
        setFilterData(props.commonCards ? props.commonCards[1] : []);
    }, [props.commonCards])


    useEffect(() => {

        setFilterData(props.commonCards ? props.commonCards[dropdownId] : [])
    }, [dropdownVal])


    useEffect(() => {

    }, [filterData])

    const saveId = (id, title) => {
        setData('actionId', id)
        window.gtag('event', 'common_problems', {
            'label': `${dropdownVal ? dropdownVal : props.dropdown['2'].value}`,
            'screen_name': 'Home',
            'value': title
          });
    }




    return (
        <div>

            <div className='bg-light common-problems' key={'commonProblem'}>
                <div className='container'>
                    <h3 className='title lightGrey'>{props.title}</h3>
                    <p className='text-normal lightGrey inter-font'>{props.description}</p>

                    <div>
                        <DropdownContainer title={'I’m renting with'} colorClass={'bg-light'} color={'lightBlue'} dropdownvalue={dropdownVal ? dropdownVal : props.dropdown['2'].value} options={props.dropdown} onDropDownChange={onDropDownClick} />
                        <ul className='card-container d-flex justify-content-between flex-wrap justify-content-md-start'>
                            {filterData !== undefined ? filterData.map((item) => {
                                return <Cards type="association" title={item.problemIssue} description={item.problemSummary} link={"/get-advice/action-plan"} id={item.problemId} onClick={saveId} dropdownDownVal={dropdownVal ? dropdownVal : props.dropdown['2'].value} />
                            }) : <p className="text-normal lightGrey">{"No issue found."}</p>}
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default CommonPorblems
